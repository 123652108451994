import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { getFileName } from "components/refbuild-downloader";
import ErrorPage from "components/error-page";
import ErrorCode from "components/error-code";

interface ReferenceTextDownloadProps {
  searchParams: URLSearchParams;
}

const loadDownload = async (signal: AbortSignal, id: string) => {
  const response = await fetch(`/api/api_retrieve/ref_download?id=${id}`, {
    signal: signal,
    headers: {
      method: "GET",
    },
  });
  if (response.ok) {
    const data = await response.blob();
    const fileName = getFileName(response.headers.get("content-disposition"));
    return { data, fileName };
  }
  throw new Error("Unable to fetch Download");
};

const ReferenceTextDownload = ({
  searchParams,
}: ReferenceTextDownloadProps): JSX.Element => {
  const id = searchParams.get("id");

  useEffect(() => {
    if (!id) return;
    const abortController = new AbortController();
    loadDownload(abortController.signal, id)
      .then(({ data, fileName }) => {
        const aTag = document.createElement("a");
        aTag.download = fileName;
        aTag.href = URL.createObjectURL(data);
        aTag.rel = "noopener";
        aTag.dispatchEvent(new MouseEvent("click"));
        // allow a minute to download before freeing the blob link
        setTimeout(() => URL.revokeObjectURL(aTag.href), 6e4);
      })
      .catch(() => {
        console.warn("file failed to automatically download");
      });
    return () => abortController.abort();
  }, [id]);

  if (!id) {
    return <ErrorPage code={ErrorCode.NotFound} />;
  }

  return (
    <Box m={2}>
      <Typography variant="h1">
        <FormattedMessage
          id="ReferenceTextDownload.header"
          defaultMessage="Reference Text Download"
        />
      </Typography>
      <Box m={2}>
        <Typography variant="h6">
          <FormattedMessage
            id="ReferenceTextDownload.downloadText"
            defaultMessage="Download in progress. If file does not download, click "
          />
          <a href={`/api/api_retrieve/export_download?id=${id}`}>here</a>
        </Typography>
      </Box>
    </Box>
  );
};

export default ReferenceTextDownload;
