/*
NOTE: This script was sent in a Word file attached in an email from Andrew Carr on the Oracle Analytics team.
The file was originally created for another Oracle Cerner team, but sent our way when we had similar questions.
The only changes to the script from the file were converting the JS to TS and correcting a typo.

Support JIRA Queue for that team [🔒 Requires Oracle VPN 🔒]: https://jira.oraclecorp.com/jira/projects/OCOMANALYT
*/
// Declare variables from oracle script.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const s: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const s_Ping: any;

function s_oraEnhancedDownloadTrackingCerner(
  downloadText: string,
  PDFhref: string,
): void {
  //Track page the download file is on
  s.prop8 = "D=pageName";
  downloadText = downloadText.replace(/&amp;/g, "&");
  s.eVar29 = s.prop23 = downloadText;
  s.event15 = PDFhref.substring(PDFhref.lastIndexOf("/") + 1, PDFhref.length);
  s.eVar15 = downloadText;
  s.eVar16 = PDFhref;
  s.events = s.apl(s.events, "event15", ",", 1);
  //Track eVar & Event
  s.linkTrackVars = "prop8,prop23,eVar29,eVar15,eVar16,events";
  s.linkTrackEvents = "event15";
  s.linkObject = 0;
  setTimeout(function () {
    s_Ping(true);
  }, 500);
}

// eslint-disable-next-line @typescript-eslint/ban-types
function trustArcEventListener(callback: Function): void {
  window.addEventListener("message", (e) => {
    let eventDataJson = null;
    try {
      eventDataJson = JSON.parse(e.data);
    } catch {
      return;
    }

    if (!eventDataJson || eventDataJson.source !== "preference_manager") {
      return;
    }

    if (eventDataJson.message === "submit_preferences") {
      callback();
    }
  });
}

export { s_oraEnhancedDownloadTrackingCerner, trustArcEventListener };
