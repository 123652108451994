import { useContext, useRef, useState } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import {
  Menu,
  MenuItem,
  Typography,
  Button,
  Switch,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import websiteURL from "nav/website-url";
import SessionContext from "contexts/session";
import getCSRF from "requests/csrf";
import WithPermission from "components/with-permission";

const changeHierarchy = async () => {
  const response = await fetch("/api/explore/change_hierarchy", {
    method: "POST",
    headers: {
      "X-CSRF-Token": getCSRF(),
      "X-Requested-With": "XMLHttpRequest",
    },
  });
  if (!response.ok) {
    console.error(response.statusText);
    return false;
  }
  const { newStatus } = await response.json();

  // returns true when in draft mode
  return newStatus === "staged";
};

const DraftPermMenuItem = WithPermission(MenuItem, "view_draft_hierarchy");

const UserMenu = (): JSX.Element => {
  const theme = useTheme();
  const username = {
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  };
  const edition = {
    cursor: "pointer",
  };
  const session = useContext(SessionContext);
  const menuEl = useRef(null);
  const [open, setOpen] = useState(false);

  const toggleHierarchy = () => {
    changeHierarchy().then((status) => {
      session.setSession({ ...session, draft: status });
      setOpen(false);
    });
  };

  const EditAnnouncementsLink = WithPermission(
    () => (
      <MenuItem
        onClick={() => setOpen(false)}
        component={Link}
        to="/announcements/manage"
      >
        <FormattedMessage
          id="UserMenu.editAnnouncements"
          defaultMessage="Edit Announcements"
        />
      </MenuItem>
    ),
    "edit_homepage",
  );

  const EditCarouselLink = WithPermission(
    () => (
      <MenuItem onClick={() => setOpen(false)} component={Link} to="/carousels">
        <FormattedMessage
          id="UserMenu.editCarousels"
          defaultMessage="Edit Carousels"
        />
      </MenuItem>
    ),
    "edit_homepage",
  );

  const EditionReleaseLink = WithPermission(
    () => (
      <MenuItem
        onClick={() => setOpen(false)}
        component={Link}
        to="/admin/release"
      >
        <FormattedMessage
          id="UserMenu.editionRelease"
          defaultMessage="Edition Release Management"
        />
      </MenuItem>
    ),
    "edition_release",
  );

  const EditionNumber = WithPermission(
    () => <>{session.edition}</>,
    "view_draft_hierarchy",
  );

  const ContentReplaceLink = WithPermission(
    () => (
      <MenuItem
        onClick={() => setOpen(false)}
        component={Link}
        to="/admin/content_replacement"
      >
        <FormattedMessage
          id="UserMenu.contentReplacement"
          defaultMessage="Content Replacement Dashboard"
        />
      </MenuItem>
    ),
    "content_replacement",
  );

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={() => setOpen(true)}
      >
        <Box px={2}>
          <Typography data-testid="edition-box" sx={edition} variant="body1">
            {session.draft ? (
              <FormattedMessage id="UserMenu.draft" defaultMessage="Draft" />
            ) : (
              <EditionNumber />
            )}
          </Typography>
        </Box>
        <Button
          endIcon={<AccountCircle data-testid="account-circle" />}
          ref={menuEl}
          color="primary"
        >
          <Typography sx={username} noWrap>
            {session.user.fullname}
          </Typography>
        </Button>
      </Box>
      <Menu
        anchorEl={menuEl.current}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setOpen(false)}
      >
        {window.location.href.includes("new.") && (
          <MenuItem
            component="a"
            href={window.location.href.replace("new.", "")}
          >
            <Switch checked={true} />
            Beta
          </MenuItem>
        )}
        <DraftPermMenuItem onClick={toggleHierarchy}>
          {session.draft ? (
            <FormattedMessage
              id="UserMenu.published"
              defaultMessage="View Published"
            />
          ) : (
            <FormattedMessage id="UserMenu.draft" defaultMessage="View Draft" />
          )}
        </DraftPermMenuItem>
        <EditAnnouncementsLink />
        <EditCarouselLink />
        <EditionReleaseLink />
        <ContentReplaceLink />
        {session.user.associate_ind && (
          <MenuItem component="a" href={`${websiteURL}/toolkit`}>
            <FormattedMessage id="UserMenu.toolkit" defaultMessage="Toolkit" />
          </MenuItem>
        )}
        <MenuItem component="a" href={`${websiteURL}/bwl_client/logout`}>
          <FormattedMessage id="UserMenu.logout" defaultMessage="Logout" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
