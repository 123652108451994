import React from "react";

export interface Domain {
  id: number;
  parent: number;
  name: string;
}

export interface Client {
  mnemonic: string;
  display: string;
  domains: Domain[];
}

export interface ClientSetup {
  clients: Client[];
  default_id: number | null;
  default_mnemonic: string;
}

const DomainContext = React.createContext<ClientSetup>({
  clients: [],
  default_id: null,
  default_mnemonic: "",
});

export default DomainContext;
