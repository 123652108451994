import React, { useState, useEffect, useCallback } from "react";
import SessionContext, { Session, Domain } from "contexts/session";
import DomainContext, { ClientSetup, Client } from "contexts/domains";
import LoadingState from "components/loading";
import LoadingBoundary from "./loading-boundary";
import Alert from "@mui/material/Alert";
import { FormattedMessage } from "react-intl";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LoadState from "components/loading";
import { setAuthUrl } from "auth/auth-url";
import ErrorPage from "components/error-page";
import ErrorCode from "components/error-code";
import { trustArcEventListener } from "utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const oracle: any;

class AuthError extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = "AuthError";
  }
}

class ProfileError extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = "ProfileError";
  }
}

enum LoginState {
  Ready,
  NoLogin,
  NoAffiliations,
  ProfileError,
}

type SessionState =
  | { state: LoginState.Ready; session: Session }
  | { state: LoginState.NoLogin }
  | { state: LoginState.NoAffiliations }
  | { state: LoginState.ProfileError };

const setDomainCookie = (domain: Domain): void => {
  const dom = JSON.stringify(domain);
  const cookieDomain = location.host.startsWith("localhost")
    ? ""
    : " Domain=cerner.com;";
  document.cookie = `selected_client_domain=${dom};${cookieDomain} SameSite=Lax;`;
};

const getCookieDomain = (): Domain | undefined => {
  const pair = document.cookie
    .split(";")
    .map((c) => c.split("="))
    .find(([name]) => {
      return name.trim() === "selected_client_domain";
    });
  if (!pair) return pair;
  return JSON.parse(pair[1]);
};

const changeMXCookie = (associate_ind: string, organization: string): void => {
  if (
    oracle.truste.api.getGdprConsentDecision().consentDecision &&
    (oracle.truste.api.getGdprConsentDecision().consentDecision.indexOf(0) !==
      -1 ||
      oracle.truste.api.getGdprConsentDecision().consentDecision.indexOf(2) !==
        -1)
  ) {
    const cookieJSON = JSON.stringify({
      associate_ind: associate_ind,
      organization: organization,
    });

    document.cookie =
      "mx_session_details=" + cookieJSON + "; Domain=cerner.com; SameSite=Lax;";
  } else {
    document.cookie =
      "mx_session_details=; Max-Age=0; path=/; Domain=cerner.com;";
  }
};

const loadUser = async (signal: AbortSignal) => {
  const response = await fetch("/api/user", {
    signal: signal,
    headers: {
      Accept: "application/json",
    },
  });
  if (response.ok || response.status === 401) return response.json();
  if (response.status === 402) throw new ProfileError("no user affiliations");
  if (response.status === 503) throw new ProfileError("profiles unavailable");
  throw new Error("unable to load user");
};

const loadDomains = async (signal: AbortSignal, session: Session) => {
  const response = await fetch("/api/domains", {
    signal: signal,
    headers: {
      Accept: "application/json",
    },
  });
  if (response.ok) {
    const clientList = await response.json();
    return { clientList, session };
  }
  throw new Error("unable to load available domains for user");
};

const domainByDefault = (
  available: Client[],
  defaultMnemonic: string,
  defaultId: number | null,
): Domain => {
  if (!defaultId) {
    const client = available[0];
    const domain = client.domains.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    })[0];
    return {
      client_mnemonic: client.mnemonic,
      cdr_id: domain.id,
      domain: domain.name,
      parent_id: domain.parent,
    };
  }
  const client = available.find((cl) => cl.mnemonic === defaultMnemonic);
  const domain = client?.domains.find((dom) => dom.id === defaultId);
  if (!client || !domain) {
    throw new Error("default domain ID had no match");
  }
  return {
    client_mnemonic: defaultMnemonic,
    cdr_id: domain.id,
    domain: domain.name,
    parent_id: domain.parent,
  };
};

const loadingNode = (
  <Backdrop open>
    <CircularProgress aria-label="loading" />
  </Backdrop>
);

const errorMessage = (
  <Alert severity="error">
    <FormattedMessage
      id="App.ded"
      defaultMessage="Unable to start application, something went wrong..."
    />
  </Alert>
);

const SessionContextProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [session, setSession] = useState<SessionState>({
    state: LoginState.NoLogin,
  });
  const [domains, setDomains] = useState<ClientSetup>({
    clients: [],
    default_id: null,
    default_mnemonic: "",
  });
  const [loading, setLoading] = useState(LoadState.Loading);

  const handleDomain = useCallback((dom: Domain) => {
    setSession((curSession) => {
      if (
        curSession.state === LoginState.NoLogin ||
        curSession.state === LoginState.ProfileError ||
        curSession.state === LoginState.NoAffiliations
      ) {
        throw new Error("invalid to set domain before user is loaded");
      }
      setDomainCookie(dom);
      return {
        state: LoginState.Ready,
        session: { ...curSession.session, domain: dom },
      };
    });
  }, []);

  useEffect(() => {
    setLoading(LoadingState.Loading);
    const abortController = new AbortController();
    loadUser(abortController.signal)
      .then((data) => {
        if (data.url) {
          setAuthUrl(data.url);
          throw new AuthError("no user session");
        }
        const session: Session = {
          user: {
            ...data.user,
            email: data.email,
            permissions: data.permissions,
            affiliations: data.affiliations,
          },
          draft: data.draft,
          realm: data.realm,
          edition: data.edition,
          domain: getCookieDomain(),
          setDomain: handleDomain,
          setSession: (newSesh) =>
            setSession({ state: LoginState.Ready, session: newSesh }),
        };

        changeMXCookie(data.user.associate_ind, data.user.organization);
        trustArcEventListener(() =>
          changeMXCookie(data.user.associate_ind, data.user.organization),
        );

        return loadDomains(abortController.signal, session);
      })
      .then(({ clientList, session }) => {
        if (!session.domain) {
          const domain = domainByDefault(
            clientList.clients,
            clientList.default_mnemonic,
            clientList.default_id,
          );
          setDomainCookie(domain);
        }
        setDomains(clientList);
        setSession({
          state: LoginState.Ready,
          session: { ...session, domain: getCookieDomain() },
        });
        setLoading(LoadingState.Done);
      })
      .catch((error: Error) => {
        if (error.name === "AbortError") {
          return;
        }
        if (error.name === "AuthError") {
          setLoading(LoadState.UserError);
          return;
        }
        if (error.name === "ProfileError") {
          setLoading(LoadState.Done);
          error.message === "no user affiliations"
            ? setSession({ state: LoginState.NoAffiliations })
            : setSession({ state: LoginState.ProfileError });
          return;
        }
        console.error(error);
        setLoading(LoadingState.Error);
      });
    return () => abortController.abort();
  }, [handleDomain]);

  return (
    <LoadingBoundary
      loading={loading}
      loadingNode={loadingNode}
      errorMessage={errorMessage}
    >
      {session.state === LoginState.NoAffiliations && (
        <ErrorPage code={ErrorCode.ProfileIncomplete} />
      )}
      {session.state === LoginState.ProfileError && (
        <ErrorPage code={ErrorCode.InternalServer} />
      )}
      {session.state === LoginState.Ready && (
        <SessionContext.Provider value={session.session}>
          <DomainContext.Provider value={domains}>
            {children}
          </DomainContext.Provider>
        </SessionContext.Provider>
      )}
    </LoadingBoundary>
  );
};

export default SessionContextProvider;
