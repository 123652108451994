import React from "react";
import csrf from "requests/csrf";
import { Box, Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Unsubscribe: React.FC = (): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();
  const token = csrf();
  return (
    <Box m={1}>
      <form action={`/api/unsubscribe/${guid}`} method="post">
        <input type="hidden" name="authenticity_token" value={token} />
        <Typography>
          <FormattedMessage
            id="Unsub.scribe"
            defaultMessage="Are you sure you want to stop following all pages?"
          />

          <Button color="primary" variant="text" type="submit">
            <FormattedMessage id="Unsub.mit" defaultMessage="Unsubscribe" />
          </Button>
        </Typography>
      </form>
    </Box>
  );
};

export default Unsubscribe;
