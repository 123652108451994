import React from "react";
import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  Link,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import BannerImg from "img/skyline_white_tem.png";
import ErrorCode from "components/error-code";

const regLink = (
  <Box px={0.5}>
    <Link href="https://profiles.cerner.com/">
      <FormattedMessage id="ErrorPage.regPage" defaultMessage="Profiles" />
    </Link>
  </Box>
);

const logoutLink = (
  <Box px={0.5}>
    <Link href="https://businesslogin.cerner.com/logout/">
      <FormattedMessage id="ErrorPage.logout" defaultMessage="log out" />
    </Link>
  </Box>
);

const errorMessages: Record<ErrorCode, JSX.Element> = {
  [ErrorCode.AuthFailure]: <></>,
  [ErrorCode.UnauthorizedAccess]: (
    <FormattedMessage
      id="ErrorPage.unauthorizedAccessMessage"
      defaultMessage="Unauthorized Access"
    />
  ),
  [ErrorCode.NotFound]: (
    <FormattedMessage
      id="ErrorPage.notFoundMessage"
      defaultMessage="Page Not Found"
    />
  ),
  [ErrorCode.InternalServer]: (
    <FormattedMessage
      id="ErrorPage.internalServerMessage"
      defaultMessage="Internal Server Error"
    />
  ),
  [ErrorCode.Unavailable]: (
    <FormattedMessage
      id="ErrorPage.unavailableMessage"
      defaultMessage="Service Unavailable"
    />
  ),
  [ErrorCode.ProfileIncomplete]: (
    <FormattedMessage
      id="ErrorPage.profileIncomplete"
      defaultMessage="Account Error"
    />
  ),
  [ErrorCode.Locked]: (
    <FormattedMessage id="ErrorPage.locked" defaultMessage="Page Locked" />
  ),
};

const errorDetails: Record<ErrorCode, JSX.Element> = {
  [ErrorCode.AuthFailure]: (
    <FormattedMessage
      id="ErrorPage.authFailureDetail"
      defaultMessage="Unable to complete login."
    />
  ),
  [ErrorCode.UnauthorizedAccess]: (
    <FormattedMessage
      id="ErrorPage.unauthorizedAccessDetail"
      defaultMessage="You do not have access to this page."
    />
  ),
  [ErrorCode.NotFound]: (
    <FormattedMessage
      id="ErrorPage.notFoundDetail"
      defaultMessage="The page you are looking for might have been removed, had its name changed, or is temporarily unavailable."
    />
  ),
  [ErrorCode.InternalServer]: (
    <FormattedMessage
      id="ErrorPage.internalServerDetail"
      defaultMessage="The server encountered an internal error and was unable to complete your request."
    />
  ),
  [ErrorCode.ProfileIncomplete]: (
    <Box textAlign="left" p={2}>
      <Typography>
        <FormattedMessage
          id="ErrorPage.affiliations"
          defaultMessage="Your account does not currently have access to Model Experience. This may be due to one of the following reasons:"
        />
      </Typography>
      <Typography>
        <FormattedMessage
          id="ErrorPage.noAffiliation"
          defaultMessage="1. Your Cerner Care account is not associated with a client affiliation. Please work with your client relationship team to get your account associated."
        />
      </Typography>
      <Typography>
        <FormattedMessage
          id="ErrorPage.incomplete"
          defaultMessage="2. Your Cerner Care account registration is not complete. To resolve, please complete the following steps"
        />
      </Typography>

      <List dense>
        <ListItem>
          <FormattedMessage
            id="ErrorPage.setupAccount"
            defaultMessage="Visit the {reg_page} to complete the setup of your account."
            values={{ reg_page: regLink }}
          />
        </ListItem>
        <ListItem>
          <FormattedMessage
            id="ErrorPage.retryLogin"
            defaultMessage="Return to Model Experience, {logout} then log back in."
            values={{ logout: logoutLink }}
          />
        </ListItem>
      </List>
    </Box>
  ),
  [ErrorCode.Unavailable]: <></>,
  [ErrorCode.Locked]: (
    <FormattedMessage
      id="ErrorPage.lockedDetail"
      defaultMessage="Page is currently locked and cannot be edited. Please try again later."
    />
  ),
};

interface SiteErrorBoundaryProps {
  code: ErrorCode;
}

const ErrorPage: React.FC<SiteErrorBoundaryProps> = ({ code }) => (
  <Container>
    <Box mt={2}>
      <img width="100%" src={BannerImg} alt="Carousel Image" />
      <Box>
        <Typography align="center" variant="subtitle2">
          {new Date().toUTCString()}
        </Typography>
      </Box>
    </Box>
    <Box mt={2}>
      <Typography align="center" variant="h3" color="primary">
        {errorMessages[code] || (
          <FormattedMessage
            id="ErrorPage.missingErrorMessage"
            defaultMessage="Something Went Wrong"
          />
        )}
      </Typography>
    </Box>
    <Box>
      <Typography align="center" variant="body2" component="div">
        {errorDetails[code]}
      </Typography>
    </Box>
  </Container>
);

export default ErrorPage;
