import { colors } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const primaryLight = "#00688C";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    button: {
      letterSpacing: "0.02em",
      textTransform: "none",
    },
    h1: {
      fontSize: "3.5rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.714rem",
    },
    h4: {
      fontSize: "1.429rem",
    },
    h5: {
      fontSize: "1.286rem",
    },
    h6: {
      fontSize: "1.143rem",
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#04536F",
      light: primaryLight,
    },
    secondary: {
      main: "#AE562C",
      contrastText: "rgba(255,255,255,0.87)",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    warning: {
      main: "#ef7521",
    },
    divider: "#5b6670",
    text: {
      primary: "#5b6670",
    },
  },
  components: {
    MuiTable: {
      defaultProps: {
        size: "small",
        stickyHeader: true,
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& > *": {
            whiteSpace: "nowrap",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        divider: {
          borderBottom: `1px solid ${colors.grey[400]}`,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: primaryLight,
        },
      },
    },
  },
});

export default theme;
