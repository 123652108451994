import React from "react";

interface Client {
  name: string;
  clientNumber: string; // right?
  mnemonic: string;
}

interface Group extends Client {
  parent: Client;
}

export interface Domain {
  client_mnemonic: string;
  cdr_id: number; // corresponds to id field of domain on the back end
  domain: string;
  parent_id: number;
}

export interface Affiliation {
  status: string;
  isPrimary: boolean;
  group: Group;
}

export interface User {
  first_name?: string;
  last_name?: string;
  email?: string;
  fullname: string;
  operator_id: string;
  associate_ind: boolean;
  organization?: string;
  business_unit?: string;
  permissions: string[];
  affiliations: Affiliation[];
}

export interface Session {
  user: User;
  realm?: string;
  edition?: string;
  draft: boolean;
  domain?: Domain;
  setDomain: (dom: Domain) => void;
  setSession: (newSession: Session) => void;
}

const SessionContext = React.createContext<Session>({
  user: {
    fullname: "",
    operator_id: "",
    associate_ind: false,
    permissions: [],
    affiliations: [],
  },
  draft: false,
  setDomain: () => undefined,
  setSession: () => undefined,
});

export default SessionContext;
