import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { IntlProvider } from "react-intl";
import { locale, messages } from "./localeData";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import theme from "./theme";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

ReactDOM.render(
  <IntlProvider locale={locale} messages={messages} defaultLocale="en">
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline /> {/* css reset */}
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </IntlProvider>,
  document.getElementById("root"),
);
