import React, { useState, Suspense, useMemo } from "react";
import { Box, Typography, Link as MuiLink } from "@mui/material";
import { Route, Link, Routes, useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";
import ErrorCode from "components/error-code";
import { Helmet } from "react-helmet";
import Unsubscribe from "components/unsubscribe";
import ReferenceTextDownload from "components/reference-text-download";
import WithPermission from "components/with-permission";
import "./styles/App.css";
import ContactForm from "components/contact-form";
import AuthenticatedRoutesLayout from "AuthenticatedRoutesLayout";

const PageViewer = React.lazy(() => import("page/page-viewer"));
const WorkflowViewer = React.lazy(() => import("workflow/workflow-viewer"));
const PageEditor = React.lazy(() => import("page-edit/page-editor"));
const SearchPage = React.lazy(() => import("search/search-page"));
const Home = React.lazy(() => import("components/home"));
const Explore = React.lazy(() => import("components/explore"));
const ManageCarousels = React.lazy(() => import("components/manage-carousel"));
const NewCarousel = React.lazy(() => import("components/new-carousel"));
const MpageExportDownload = React.lazy(
  () => import("components/mpage-export-download"),
);
const ErrorPage = React.lazy(() => import("components/error-page"));
const Login = React.lazy(() => import("auth/login"));
const EditionReleaseManagement = React.lazy(
  () => import("components/edition-release-management"),
);
const AnnouncementManager = React.lazy(
  () => import("components/announcement-manager"),
);
const PageOverviewTabs = React.lazy(
  () => import("components/page-overview-tabs"),
);
const ContentReplacement = React.lazy(
  () => import("components/content-replacement"),
);

const ContentReplaceDashboard = WithPermission(
  ContentReplacement,
  "content_replacement",
);

const PageEditorView = WithPermission(PageEditor, "edit_pages");

function App(): JSX.Element {
  const location = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const [term, setTerm] = useState<string>(searchParams.get("term") || "");

  return (
    <Box>
      {/* @ts-ignore */}
      <Helmet>
        <title>Model Experience</title>
      </Helmet>
      <Routes>
        <Route
          path="/login"
          element={
            <Suspense fallback={<Skeleton height="100vh" />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <AuthenticatedRoutesLayout
              term={term}
              setTerm={setTerm}
              searchParams={searchParams}
            />
          }
        >
          <Route
            path="whats_new"
            element={<PageViewer defaultSection="current_edition" />}
          />
          <Route
            path="/pages/:id/edit"
            element={
              <PageEditorView
                fallback={<ErrorPage code={ErrorCode.UnauthorizedAccess} />}
              />
            }
          />
          {["/pages/*", "/foundations/*", "/outcomes/*"].map((path, index) => (
            <Route key={index} path={path} element={<PageViewer />} />
          ))}
          {["/explore/*", "/site_map"].map((path, index) => (
            <Route key={index} path={path} element={<Explore />} />
          ))}
          <Route
            path="/search"
            element={<SearchPage onTermUpdate={setTerm} />}
          />
          <Route path="/" element={<Home />} />
          <Route path="/carousels/new" element={<NewCarousel />} />
          <Route path="/carousels" element={<ManageCarousels />} />
          <Route path="/workflow/viewer/:id" element={<WorkflowViewer />} />
          <Route
            path="/api_retrieve/mpage_export_download"
            element={<MpageExportDownload searchParams={searchParams} />}
          />
          <Route
            path="/api_retrieve/reference_text_download"
            element={<ReferenceTextDownload searchParams={searchParams} />}
          />
          <Route path="/contact_us" element={<ContactForm />} />
          <Route path="/admin/release" element={<EditionReleaseManagement />} />
          <Route
            path="/admin/content_replacement"
            element={
              <ContentReplaceDashboard
                fallback={<ErrorPage code={ErrorCode.NotFound} />}
              />
            }
          />
          <Route
            path="/announcements/manage"
            element={<AnnouncementManager />}
          />
          <Route path="/pages" element={<PageOverviewTabs />} />
          <Route
            path="/unsubscribe/goodbye"
            element={
              <Box m={1}>
                <Typography>
                  You are no longer watching pages. Would you like to&nbsp;
                  <MuiLink component={Link} to="/explore">
                    Explore Model
                  </MuiLink>
                  ?
                </Typography>
              </Box>
            }
          />
          <Route path="/unsubscribe/:guid" element={<Unsubscribe />} />
          <Route element={<ErrorPage code={ErrorCode.NotFound} />} />
        </Route>
      </Routes>
    </Box>
  );
}

export default App;
