import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

interface IReply {
  unread: number;
}

const checkUnread = async (signal: AbortSignal) => {
  const response = await fetch("/api/announcements/unread", {
    signal,
    headers: { Accept: "application/json" },
  });
  if (!response.ok) {
    console.error("Unable to request unread announcement count");
    return 0;
  }
  const reply: IReply = await response.json();
  return reply.unread;
};

const AnnouncementIndicator = (): JSX.Element => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const controller = new AbortController();
    checkUnread(controller.signal).then(setCount);
    return () => controller.abort();
  }, []);
  return (
    <Button
      aria-label="view announcements"
      component={Link}
      to="/whats_new?section=announcements"
      onClick={() => setTimeout(() => setCount(0), 750)}
    >
      <Badge badgeContent={count} color="secondary">
        <NotificationsIcon />
      </Badge>
    </Button>
  );
};

export default AnnouncementIndicator;
