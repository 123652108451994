import React, { useState, useCallback, useEffect, useRef } from "react";
import IconDownload from "@mui/icons-material/GetApp";
import ErrorIcon from "@mui/icons-material/Error";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import { CircularProgress, IconButton } from "@mui/material";
import csrf from "requests/csrf";

interface DownloadProps {
  id: number;
  model: string;
}

export enum DownloadState {
  Success,
  Ready,
  Loading,
  Failed,
}

export enum Transition {
  Error,
  Done,
}

export type Action =
  | { type: Transition.Error; error: Error }
  | { type: Transition.Done };

export type Updater = (action: Action) => void;

async function startDownload(
  audit: string,
  id: number,
  notify: Updater,
  signal: AbortSignal,
) {
  try {
    const response = await fetch("/api/ref_build_downloads", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf(),
      },
      body: JSON.stringify({ model: audit, record_id: id }),
      signal,
    });
    if (!response.ok) {
      throw new Error("unable to start download");
    }
    const data = await response.json();
    awaitJob(data.job_id, notify, signal);
  } catch (e) {
    notify({ type: Transition.Error, error: e as Error });
  }
}

async function awaitJob(jobId: string, notify: Updater, signal: AbortSignal) {
  if (signal.aborted) {
    return;
  }
  try {
    const response = await fetch(`/api/ref_build_downloads/${jobId}`, {
      signal,
    });
    if (!response.ok) {
      throw new Error("unable to check download status");
    }
    const data = await response.json();
    switch (data.status) {
      case "error":
        throw new Error("download failed");
      case "completed":
        getFile(jobId, notify, signal);
        break;
      default:
        setTimeout(awaitJob, 5000, jobId, notify, signal);
    }
  } catch (e) {
    notify({ type: Transition.Error, error: e as Error });
  }
}

export function getFileName(dispositionHeader: string | null): string {
  if (!dispositionHeader) {
    throw new Error("Content-Disposition header missing");
  }
  const match = dispositionHeader.match(/filename="([^"]+)"/);
  if (!match || match.length != 2) {
    throw new Error("unexpected header format");
  }
  return match[1];
}

async function getFile(jobId: string, notify: Updater, signal: AbortSignal) {
  try {
    const response = await fetch(`/api/ref_build_downloads/${jobId}/download`, {
      signal,
    });
    if (!response.ok) {
      throw new Error("failed to retrieve file");
    }
    const data = await response.blob();
    const aTag = document.createElement("a");
    aTag.download = getFileName(response.headers.get("content-disposition"));
    aTag.href = URL.createObjectURL(data);
    aTag.rel = "noopener";
    aTag.dispatchEvent(new MouseEvent("click"));
    // allow a minute to download before freeing the blob link
    setTimeout(() => URL.revokeObjectURL(aTag.href), 6e4);
    notify({ type: Transition.Done });
  } catch (e) {
    notify({ type: Transition.Error, error: e as Error });
  }
}

const Downloader: React.FC<DownloadProps> = ({ id, model }) => {
  const [loading, setLoading] = useState<DownloadState>(DownloadState.Ready);
  const updater = useCallback((action: Action) => {
    switch (action.type) {
      case Transition.Error:
        if (action.error.name !== "AbortError") {
          setLoading(DownloadState.Failed);
        }
        break;
      case Transition.Done:
        setLoading(DownloadState.Success);
        break;
    }
  }, []);
  const aborter = useRef(new AbortController());
  const handleClick = () => {
    setLoading(DownloadState.Loading);
    startDownload(model, id, updater, aborter.current.signal);
  };
  useEffect(
    () => () => {
      aborter.current.abort();
    },
    [],
  );
  switch (loading) {
    case DownloadState.Loading:
      return (
        <IconButton aria-label="downloading" size="large">
          <CircularProgress size="1rem" />
        </IconButton>
      );
    case DownloadState.Failed:
      return (
        <IconButton
          onClick={handleClick}
          aria-label="download failed"
          size="large"
        >
          <ErrorIcon />
        </IconButton>
      );
    case DownloadState.Success:
      return (
        <IconButton
          color="primary"
          onClick={handleClick}
          aria-label="download complete"
          size="large"
        >
          <CloudDoneIcon />
        </IconButton>
      );
    default:
      return (
        <IconButton
          color="primary"
          onClick={handleClick}
          aria-label="download"
          size="large"
        >
          <IconDownload />
        </IconButton>
      );
  }
};

export default Downloader;
