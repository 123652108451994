import React from "react";
import LoadState from "components/loading";
import { LinearProgress } from "@mui/material";
import { Navigate } from "react-router-dom";

interface BoundaryProps {
  loading: LoadState;
  loadingNode?: React.ReactNode;
  errorMessage: React.ReactNode;
  children?: React.ReactNode;
}

const LoadingBoundary: React.FC<BoundaryProps> = ({
  loading,
  loadingNode = <LinearProgress />,
  errorMessage,
  children,
}) => {
  if (loading === LoadState.Loading) {
    return <>{loadingNode}</>;
  }

  if (loading === LoadState.Error) {
    return <>{errorMessage}</>;
  }

  if (loading === LoadState.UserError) {
    return (
      <Navigate to="/login" state={{ referrer: window.location.pathname }} />
    );
  }
  return <>{children}</>;
};

export default LoadingBoundary;
