import React from "react";
import SessionContext, { Session } from "contexts/session";

interface FallbackProp {
  fallback?: JSX.Element;
}

const hasPermission = (session: Session, permission: string): boolean =>
  session.user.permissions.some((key) => key === permission);

const WithPermission = <T,>(
  WrappedComponent: React.ComponentType<T>,
  permission: string,
): React.ComponentType<T & FallbackProp> => {
  class PermissionWrapper extends React.Component<T & FallbackProp> {
    static contextType = SessionContext;
    render() {
      if (hasPermission(this.context as Session, permission)) {
        return <WrappedComponent {...this.props} />;
      }
      return this.props.fallback || <></>;
    }
  }
  return PermissionWrapper;
};

export default WithPermission;
