import React from "react";
import { CircularProgress } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import Loading from "components/loading";

const LoadingIndicator = ({ loading }: { loading: number }): JSX.Element => {
  switch (loading) {
    case Loading.Loading:
      return <CircularProgress size="1.5rem" />;
    case Loading.Error:
      return <ErrorIcon />;
    default:
      return <></>;
  }
};

export default LoadingIndicator;
