export const setAuthUrl = (url: string): void => {
  window.localStorage.setItem("authUrl", url);
};

export const getAuthUrl = (): string | null => {
  const stored = window.localStorage.getItem("authUrl");
  if (stored) {
    return stored;
  } else {
    console.warn("attempted to load auth URL without having set it first");
    return null;
  }
};

/*
Provides the URL for the signature workflow. If the realm is unavailable this assumes
auth is disabled and return URL is sent back directly so that the signature is skipped.
If auth is actually enabled this defers the error to the back end. 
*/
export const getSignatureUrl = (returnTo: string, realm?: string): string => {
  if (!realm) return `${window.location.origin}${returnTo}`;
  const stored = window.localStorage.getItem("authUrl");
  const params = new URLSearchParams();
  params.set("ReturnUrl", `${window.location.origin}${returnTo}`);
  return `${stored}/provider/${realm}?${params.toString()}`;
};

export const popReferrer = (): string => {
  const val = window.sessionStorage.getItem("returnTo") || "/";
  window.sessionStorage.removeItem("returnTo");
  return val;
};

export const pushReferrer = (path: string): void => {
  window.sessionStorage.setItem("returnTo", path);
};
