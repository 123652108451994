/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { FormattedMessage } from "react-intl";
import DomainContext, { Client } from "contexts/domains";
import SessionContext from "contexts/session";

interface Props {
  open: boolean;
  onClose: () => void;
}

const optionLabel = (client: Client) =>
  `${client.display} (${client.mnemonic})`;

const ClientDialog = (props: Props): JSX.Element => {
  const { clients } = useContext(DomainContext);
  const session = useContext(SessionContext);
  const [client, setClient] = useState<Client | null>(null);
  const handleSubmit = () => {
    session.setDomain({
      client_mnemonic: client!.mnemonic,
      cdr_id: client!.domains[0].id,
      domain: client!.domains[0].name,
      parent_id: client!.domains[0].parent,
    });
    props.onClose();
  };
  return (
    <Dialog {...props} fullWidth>
      <DialogTitle>
        <FormattedMessage
          id="ClientDialog.Client"
          defaultMessage="Choose Customer"
        />
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          data-testid="client-select"
          options={clients}
          getOptionLabel={optionLabel}
          renderInput={(props) => (
            <TextField {...props} variant="outlined" label="Search" />
          )}
          value={client}
          onChange={(event: unknown, newValue: Client | null) => {
            setClient(newValue);
          }}
        />
        <Typography variant="subtitle2">
          <FormattedMessage
            id="ClientDialog.Tip"
            defaultMessage="Search by mnemonic or organization"
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          <FormattedMessage id="ClientDialog.Cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={client === null}
        >
          <FormattedMessage id="ClientDialog.Submit" defaultMessage="Submit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientDialog;
