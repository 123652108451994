enum ErrorCode {
  AuthFailure,
  InternalServer,
  NotFound,
  Unavailable,
  UnauthorizedAccess,
  ProfileIncomplete,
  Locked,
}

export default ErrorCode;
