import { useContext, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import { Grid2 as Grid, Typography, Box, useTheme } from "@mui/material";
import { IconButton, Menu, MenuItem, Button } from "@mui/material";
import SessionContext, { Domain as UserDomain } from "contexts/session";
import DomainContext, {
  Client,
  Domain as ClientDomain,
} from "contexts/domains";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClientDialog from "components/client-dialog";

interface ChosenDomain {
  client: Client;
  domain: ClientDomain;
}

const getDomain = (available: Client[], current: UserDomain): ChosenDomain => {
  const client =
    available.find((cl) => cl.mnemonic === current.client_mnemonic) ||
    available[0];
  const domain =
    client.domains.find((d) => d.name === current.domain) || client.domains[0];
  return { client, domain };
};

function ClientPicker(): JSX.Element {
  const theme = useTheme();
  const appBar = {
    boxShadow: "none",
  };
  const domainArea = {
    backgroundColor: theme.palette.grey[200],
    textAlign: "center",
    background: `linear-gradient(-45deg, ${theme.palette.grey[200]} calc(100% - 32px), ${theme.palette.common.white} 32px)`,
  };
  const clientText = {
    marginLeft: theme.spacing(2),
  };
  const clientName = {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  };
  const mnemonicText = {
    margin: `auto 0 auto ${theme.spacing(2)}`,
  };
  const domainText = {
    color: theme.palette.grey[500],
    margin: `auto 0 auto ${theme.spacing(3)}`,
    flexGrow: 1,
  };
  const domainButton = {
    padding: theme.spacing(0, 1),
  };
  const clientButton = {
    color: theme.palette.primary.contrastText,
  };
  const pickerLeft = {
    background: `linear-gradient(-45deg, ${theme.palette.common.white} 32px, ${theme.palette.primary.main} 32px)`,
  };
  const session = useContext(SessionContext);
  const availableDomains = useContext(DomainContext);
  const menuEl = useRef(null);
  const [domainOpen, setDomainOpen] = useState(false);
  const [clientOpen, setClientOpen] = useState(false);
  if (availableDomains.clients.length === 0 || !session.domain) {
    return <></>;
  }
  const { client, domain } = getDomain(
    availableDomains.clients,
    session.domain,
  );
  const handleDomainChange = (dom: ClientDomain) => {
    session.setDomain({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      client_mnemonic: session.domain!.client_mnemonic,
      cdr_id: dom.id,
      domain: dom.name,
      parent_id: dom.parent,
    });
  };
  return (
    <AppBar position="static" sx={appBar}>
      <Grid container>
        <Grid container sx={pickerLeft} size={9}>
          <Grid sx={clientText}>
            <Box display="flex">
              <Box sx={clientName}>
                <Typography variant="h6" noWrap>
                  {client.display}
                </Typography>
              </Box>
              <Box sx={mnemonicText}>
                <Typography noWrap>({client.mnemonic})</Typography>
              </Box>
              {availableDomains.clients.length > 1 && (
                <Box sx={mnemonicText}>
                  <Button
                    size="small"
                    sx={clientButton}
                    onClick={() => setClientOpen(true)}
                  >
                    Select
                  </Button>
                  <ClientDialog
                    open={clientOpen}
                    onClose={() => setClientOpen(false)}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={domainArea} size={3}>
          <Grid sx={domainText}>
            <Typography noWrap component="span">
              {domain.name}
            </Typography>
            {client.domains.length > 1 && (
              <>
                <IconButton
                  sx={domainButton}
                  ref={menuEl}
                  onClick={() => setDomainOpen(true)}
                  aria-label="pick domain"
                  size="large"
                >
                  <ArrowDropDownIcon />
                </IconButton>
                <Menu
                  open={domainOpen}
                  anchorEl={menuEl.current}
                  onClose={() => setDomainOpen(false)}
                >
                  {client.domains
                    .sort((a, b) => (a.name < b.name ? -1 : 1))
                    .map((dom) => (
                      <MenuItem
                        key={dom.id}
                        onClick={() => {
                          handleDomainChange(dom);
                          setDomainOpen(false);
                        }}
                      >
                        {dom.name}
                      </MenuItem>
                    ))}
                </Menu>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default ClientPicker;
