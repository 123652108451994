import {
  Box,
  Typography,
  Grid2 as Grid,
  Toolbar,
  Link as MuiLink,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import OHLogoImg from "img/Oracle_Health_rgb_rev.png";

const Bullet = () => (
  <Typography color="primary" variant="body2">
    &bull;
  </Typography>
);

const HomeFooter = (): JSX.Element => (
  <Box mt={2} bgcolor="grey.200" color="primary.contrastText">
    <Toolbar variant="dense">
      <Grid container spacing={2}>
        <Grid>
          <MuiLink component={Link} to="/explore?tab=site_map">
            <FormattedMessage id="Footer.siteMap" defaultMessage="Site Map" />
          </MuiLink>
        </Grid>
        <Grid>
          <Bullet />
        </Grid>
        <Grid>
          <MuiLink component={Link} to="/foundations/User Guide">
            <FormattedMessage
              id="Footer.userGuide"
              defaultMessage="User Guide"
            />
          </MuiLink>
        </Grid>
        <Grid>
          <Bullet />
        </Grid>
        <Grid>
          <MuiLink href="https://www.oracle.com/corporate/acquisitions/cerner/privacy/ ">
            <FormattedMessage
              id="Footer.privacyPolicy"
              defaultMessage="Privacy Policy"
            />
          </MuiLink>
        </Grid>
        <Grid>
          <Bullet />
        </Grid>
        <Grid>
          <MuiLink href="https://www.oracle.com/legal/privacy/privacy-choices.html">
            <FormattedMessage
              id="Footer.doNotSellMyInfo"
              defaultMessage="Do Not Sell My Info"
            />
          </MuiLink>
        </Grid>
        <Grid>
          <Bullet />
        </Grid>
        <Grid>
          <MuiLink href="https://community.oracle.com/oraclehealth">
            <FormattedMessage
              id="Footer.oracleHealthCommunity"
              defaultMessage="Oracle Health Community"
            />
          </MuiLink>
        </Grid>
        <Grid>
          <Bullet />
        </Grid>
        <Grid>
          <MuiLink component={Link} to="/contact_us">
            <FormattedMessage
              id="Footer.contactUs"
              defaultMessage="Contact Us"
            />
          </MuiLink>
        </Grid>
      </Grid>
    </Toolbar>
    <Box bgcolor="primary.main" width="100%" p={1}>
      <Toolbar>
        <Grid container spacing={4} alignItems="center">
          <Grid>
            <MuiLink href="https://www.oracle.com/health/">
              <img width="129px" src={OHLogoImg} alt="Oracle Health Logo" />
            </MuiLink>
          </Grid>
          <Grid>
            <Typography variant="body2">
              <MuiLink
                component={Link}
                to="https://www.oracle.com/legal/copyright.html"
                style={{ color: "white", textDecoration: "none" }}
              >
                <FormattedMessage
                  id="Footer.copyrightText"
                  defaultMessage="© {year} Oracle"
                  values={{ year: new Date().getUTCFullYear() }}
                />
              </MuiLink>
            </Typography>
          </Grid>
          <Grid>
            <div id="teconsent" data-testid="teconsent"></div>
          </Grid>
        </Grid>
      </Toolbar>
    </Box>
  </Box>
);

export default HomeFooter;
