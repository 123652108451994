import {
  Dispatch,
  SetStateAction,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Alert, Skeleton } from "@mui/material";
import SessionContextProvider from "components/session-context-provider";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MenuBar from "components/menu-bar";
import ClientPicker from "components/client-picker";
import { FormattedMessage } from "react-intl";
import HomeFooter from "components/home-footer";
import { trustArcEventListener } from "utils";

// Declare variables from oracle script.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const oracle: any;

type AuthenticatedRoutesLayoutProps = {
  term: string;
  setTerm: Dispatch<SetStateAction<string>>;
  searchParams: URLSearchParams;
};

const AuthenticatedRoutesLayout = ({
  setTerm,
  term,
  searchParams,
}: AuthenticatedRoutesLayoutProps) => {
  const [analyticsEnabled, setAnalyticsEnabled] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const handleSearch = useCallback(
    (term: string) => {
      const params = new URLSearchParams(location.search);
      params.set("term", term);
      navigate(`/search?${params}`);
      setTerm(term);
    },
    [navigate, location.search, setTerm],
  );

  // This function needs to be a callback so it can be used in the useEffect on
  // first render, but it also needs to be a separate function because it calls
  // itself within the trustArcEventListener. This is so that the warning banner
  // can be displayed whenever the TrustArc settings are changed without reloading
  // the page.
  const functionalityCheck = useCallback(() => {
    let retries = 3;
    const timeouts: NodeJS.Timeout[] = [];

    const testAnalyticsVariable = () => {
      if (
        oracle.truste.api.getGdprConsentDecision().consentDecision &&
        (oracle.truste.api
          .getGdprConsentDecision()
          .consentDecision.indexOf(0) !== -1 ||
          oracle.truste.api
            .getGdprConsentDecision()
            .consentDecision.indexOf(2) !== -1)
      )
        return setAnalyticsEnabled(true);
      setAnalyticsEnabled(false);
      if (retries <= 0) return;
      retries--;
      // Sometimes script takes longer to load. Retry a few times...
      const timeout = setTimeout(() => {
        console.log(
          "%c ⓘ Functional cookie consent missing...",
          "color: #42bff5",
        );
        testAnalyticsVariable();
      }, 300);
      timeouts.push(timeout);
    };

    // Wait for script to load.
    const initialTimer = setTimeout(() => {
      testAnalyticsVariable();
      trustArcEventListener(() => functionalityCheck());
    }, 750);
    timeouts.push(initialTimer);

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  useEffect(() => {
    setTerm(searchParams.get("term") || "");
  }, [searchParams, setTerm]);

  useEffect(() => {
    functionalityCheck();
  }, [functionalityCheck]);

  return (
    <SessionContextProvider>
      <Suspense fallback={<Skeleton height="100vh" />}>
        <div id="wrap">
          <MenuBar
            term={term}
            onTermUpdate={setTerm}
            handleSearch={handleSearch}
          />
          <ClientPicker />
          <main>
            {!analyticsEnabled && (
              <>
                <Alert severity="warning">
                  <FormattedMessage
                    id="App.analyticsWarning"
                    defaultMessage="It looks like you have an ad blocker or are blocking functional cookies. Please disable any ad blockers and activate functional cookies to ensure the complete functionality of this website."
                  />
                </Alert>
              </>
            )}
            <Outlet />
          </main>
          <div id="footer">
            <HomeFooter />
          </div>
        </div>
      </Suspense>
    </SessionContextProvider>
  );
};

export default AuthenticatedRoutesLayout;
